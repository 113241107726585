import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

const HeadingStyled = styled.div`
  max-width: 1210px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  h2 {
    font-weight: 700;
  }

  p {
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    opacity: 0.6;
  }
`

//

export default function TryNow() {
  return (<section className="max-w-[980px] pt-[55px] pb-[65px] mx-auto overflow-hidden text-center">
    <style global jsx>{`
      .extension_image {
        margin-top: 55px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        overflow-x: auto;
        -webkit-scrollbar-width: none;
        -moz-scrollbar-width: none;
        -ms-scrollbar-width: none;
        scrollbar-width: none;
      }

      .extension_image .browser_logo {
        backface-visibility: hidden;
        transition: transform 0.2s linear 0s;
        margin-right: 60px;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-bottom: 1.25rem;
      }

      .extension_image .browser_logo:hover {
        transform: scale(0.98) translate3d(0px, 0px, 0px) perspective(1px);
      }

      .extension_image .browser_logo a:not(.svg_link) {
        background-color: rgb(var(--color-button-muted));
        margin-top: 20px;
        padding: 0.5rem 1.25rem;
        border-radius: 9999px;
      }

      @media (min-width: 768px) {
        .extension_image {
          -webkit-box-pack: center;
          justify-content: center;
          flex-wrap: nowrap;
        }

        .extension_image::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        .extension_image .browser_logo {
          margin-right: 80px;
        }
      }
    `}</style>
    <HeadingStyled><h2
      className="text-heading_secondary">Try XX now!</h2><p className="mt-5 text-paragraph">Here are the product
      features Here are the product features</p></HeadingStyled>
    <ScrollContainer className="extension_image">
      <div className="browser_logo"><a
        href="#"
        className="svg_link" draggable="false">
        <svg width={80} height={80} viewBox="0 0 80 80" fill="none">
          <g clipPath="url(#chromeclip0)">
            <path
              d="M6.34 18.39v30.684h17.907l15.729-27.247h35.62C68.966 8.857 55.51 0 39.976 0 25.844 0 13.454 7.308 6.34 18.39z"
              fill="#DB4437"/>
            <path
              d="M6.34 18.39v30.684h17.907l15.729-27.247h35.62C68.966 8.857 55.51 0 39.976 0 25.844 0 13.454 7.308 6.34 18.39z"
              fill="url(#chromepaint0_linear)"/>
            <path d="M6.34 18.39l17.955 30.733.387-.242L6.63 17.907c-.096.193-.193.339-.29.484z" fill="#3E2723"
                  fillOpacity="0.15"/>
            <path
              d="M38.088 79.903l17.617-17.616V49.074H24.247L6.34 18.391C2.323 24.586 0 32.039 0 39.976c0 21.44 16.89 38.96 38.088 39.927z"
              fill="#0F9D58"/>
            <path
              d="M38.088 79.903l17.617-17.616V49.074H24.247L6.34 18.391C2.323 24.586 0 32.039 0 39.976c0 21.44 16.89 38.96 38.088 39.927z"
              fill="url(#chromepaint1_linear)"/>
            <path d="M37.604 79.903c.146 0 .34 0 .484.049l17.278-30.248-.387-.242-17.375 30.441z" fill="#263238"
                  fillOpacity="0.15"/>
            <path d="M38.088 79.903l17.617-17.616V49.074l-17.617 30.83z" fill="#FFCD40"/>
            <path d="M38.088 79.903l17.617-17.616V49.074l-17.617 30.83z" fill="url(#chromepaint2_linear)"/>
            <path
              d="M39.976 21.827l15.729 27.247-17.617 30.83c.63.048 1.259.048 1.888.048 22.069 0 39.976-17.907 39.976-39.976a40.004 40.004 0 00-4.356-18.149h-35.62z"
              fill="#FFCD40"/>
            <path
              d="M39.976 21.827l15.729 27.247-17.617 30.83c.63.048 1.259.048 1.888.048 22.069 0 39.976-17.907 39.976-39.976a40.004 40.004 0 00-4.356-18.149h-35.62z"
              fill="url(#chromepaint3_linear)"/>
            <path d="M75.596 21.827h-35.62v9.486l35.62-9.486z" fill="url(#chromepaint4_radial)"/>
            <path d="M6.34 18.39l17.907 30.684 8.13-4.694L6.34 18.39z" fill="url(#chromepaint5_radial)"/>
            <path d="M55.705 49.074l-8.082-4.694-9.535 35.523 17.617-30.829z" fill="url(#chromepaint6_radial)"/>
            <path
              d="M39.976 58.125c10.023 0 18.149-8.126 18.149-18.15 0-10.023-8.126-18.148-18.15-18.148-10.023 0-18.148 8.125-18.148 18.149 0 10.023 8.125 18.149 18.149 18.149z"
              fill="#F1F1F1"/>
            <path
              d="M39.976 54.495c8.019 0 14.519-6.5 14.519-14.52 0-8.018-6.5-14.518-14.52-14.518-8.018 0-14.518 6.5-14.518 14.519s6.5 14.519 14.519 14.519z"
              fill="#4285F4"/>
            <path
              d="M75.354 21.343H39.976c-10.018 0-18.149 8.13-18.149 18.149v.435c0-10.018 8.13-18.148 18.149-18.148H75.547c-.048-.146-.096-.29-.193-.436z"
              fill="#3E2723" fillOpacity="0.2"/>
            <path
              d="M40.266 58.125h-.29c-6.727 0-12.583-3.678-15.73-9.099L6.34 18.391c-.048.048-.097.145-.145.193L24.247 49.51c3.146 5.42 9.002 9.099 15.729 9.099 5.033 0 9.63-2.081 12.922-5.42a18.162 18.162 0 01-12.438 4.936c-.097.048-.145 0-.194 0z"
              fill="#fff" fillOpacity="0.1"/>
            <path
              d="M46.316 57.011c.097-.048.193-.096.29-.096-.097 0-.193.048-.29.096zM47.235 56.624c.29-.096.533-.242.823-.387-.29.145-.532.29-.823.387zM45.445 57.302c.048 0 .096-.049.145-.049-.049 0-.097.049-.145.049zM55.705 49.074zM48.397 56.092c.242-.097.435-.242.677-.387-.242.145-.484.242-.677.387z"
              fill="#fff" fillOpacity="0.1"/>
            <path opacity="0.1"
                  d="M58.076 41.428v-.29c.049.048.049.145 0 .29zM52.898 53.188l1.161-1.161.194-.34a19.902 19.902 0 01-1.355 1.501z"
                  fill="#3E2723"/>
            <path opacity="0.1"
                  d="M57.64 35.62a18.78 18.78 0 01.678 4.985c0 2.759-.629 5.324-1.742 7.695 1.258-2.468 2.033-5.324 2.033-8.324 0-10.018-8.131-18.149-18.15-18.149h-.241c8.42.097 15.487 5.953 17.423 13.793z"
                  fill="#3E2723"/>
            <path opacity="0.1"
                  d="M57.98 42.492c0-.096 0-.145.048-.242-.048.097-.048.194-.048.242zM45.59 57.254c.242-.097.484-.146.677-.242-.193.096-.435.145-.677.242zM46.606 56.915c.194-.097.436-.145.63-.242a3.36 3.36 0 00-.63.242zM49.026 55.705c.097-.049.145-.097.242-.145-.048.048-.145.096-.242.145zM48.058 56.237c.097-.048.194-.097.339-.194-.145.097-.242.146-.339.194zM40.218 58.125h.048a20.313 20.313 0 005.179-.823 16.884 16.884 0 01-5.227.823z"
                  fill="#3E2723"/>
            <path
              d="M55.705 49.074a18.494 18.494 0 01-6.437 6.534c-.097.048-.145.097-.242.145-.242.145-.436.242-.678.387a2.776 2.776 0 00-.338.194c-.242.145-.533.242-.823.387a3.36 3.36 0 01-.63.242c-.096.048-.193.097-.29.097-.242.097-.435.145-.677.242-.049 0-.097.048-.145.048a16.81 16.81 0 01-5.179.823h.145c4.84 0 9.196-1.888 12.438-4.937.484-.483.92-1.016 1.356-1.548l1.5-2.614z"
              fill="#fff" fillOpacity="0.1"/>
            <path opacity="0.1"
                  d="M55.705 49.074a18.494 18.494 0 01-6.437 6.534c-.097.048-.145.097-.242.145-.242.145-.436.242-.678.387a2.776 2.776 0 00-.338.194c-.242.145-.533.242-.823.387a3.36 3.36 0 01-.63.242c-.096.048-.193.097-.29.097-.242.097-.435.145-.677.242-.049 0-.097.048-.145.048a16.81 16.81 0 01-5.179.823h.145c4.84 0 9.196-1.888 12.438-4.937.484-.483.92-1.016 1.356-1.548l1.5-2.614z"
                  fill="#3E2723"/>
            <path
              d="M57.64 35.62c-.048-.194-.096-.339-.145-.532.34 1.161.533 2.323.63 3.581v.097c.048.387.048.823.048 1.21s0 .726-.048 1.113v.29c0 .29-.049.581-.097.872 0 .096 0 .145-.048.242a17.808 17.808 0 01-2.275 6.533l-1.5 2.613-.194.34-15.923 27.876h.533l17.277-30.248c.242-.436.484-.92.726-1.404a17.775 17.775 0 001.743-7.695 20.94 20.94 0 00-.726-4.888z"
              fill="#fff" fillOpacity="0.2"/>
            <path
              d="M39.976.436c22.02 0 39.83 17.761 39.976 39.733v-.242C79.952 17.907 62.045 0 39.976 0 17.906 0 0 17.907 0 39.976v.242C.145 18.246 17.955.436 39.976.436z"
              fill="#fff" fillOpacity="0.2"/>
            <path
              d="M39.976 79.516c22.02 0 39.83-17.762 39.976-39.734v.242C79.952 62.094 62.045 80 39.976 80 17.906 80 0 62.045 0 39.976v-.242c.145 21.972 17.955 39.782 39.976 39.782z"
              fill="#3E2723" fillOpacity="0.15"/>
            <path
              d="M39.976 79.952c22.078 0 39.976-17.898 39.976-39.976S62.054 0 39.976 0 0 17.898 0 39.976s17.898 39.976 39.976 39.976z"
              fill="url(#chromepaint7_radial)"/>
          </g>
          <defs>
            <linearGradient id="chromepaint0_linear" x1="12.0391" y1="34.4641" x2="35.8912" y2="20.5314"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#A52714" stopOpacity="0.6"/>
              <stop offset="0.66" stopColor="#A52714" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="chromepaint1_linear" x1="48.8936" y1="67.4193" x2="22.3919" y2="51.895"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#055524" stopOpacity="0.4"/>
              <stop offset="0.33" stopColor="#055524" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="chromepaint2_linear" x1="37.2686" y1="22.295" x2="43.9427" y2="51.5199"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#EA6100" stopOpacity="0.3"/>
              <stop offset="0.66" stopColor="#EA6100" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="chromepaint3_linear" x1="50.2641" y1="19.3273" x2="56.9381" y2="48.5522"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#EA6100" stopOpacity="0.3"/>
              <stop offset="0.66" stopColor="#EA6100" stopOpacity={0}/>
            </linearGradient>
            <radialGradient id="chromepaint4_radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(38.245 21.784) scale(38.1987)">
              <stop stopColor="#3E2723" stopOpacity="0.2"/>
              <stop offset={1} stopColor="#3E2723" stopOpacity={0}/>
            </radialGradient>
            <radialGradient id="chromepaint5_radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(6.306 18.41) scale(35.4573)">
              <stop stopColor="#3E2723" stopOpacity="0.2"/>
              <stop offset={1} stopColor="#3E2723" stopOpacity={0}/>
            </radialGradient>
            <radialGradient id="chromepaint6_radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(39.908 40.043) scale(39.9215)">
              <stop stopColor="#263238" stopOpacity="0.2"/>
              <stop offset={1} stopColor="#263238" stopOpacity={0}/>
            </radialGradient>
            <radialGradient id="chromepaint7_radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(11.942 10.91) scale(80.3019)">
              <stop stopColor="#fff" stopOpacity="0.1"/>
              <stop offset={1} stopColor="#fff" stopOpacity={0}/>
            </radialGradient>
            <clipPath id="chromeclip0">
              <path fill="#fff" d="M0 0H79.9516V80H0z"/>
            </clipPath>
          </defs>
        </svg>
      </a><a href="#"
             className="noselect " draggable="false">Chrome</a></div>
      <div className="browser_logo"><a
        href="#"
        className="svg_link" draggable="false">
        <svg width={69} height={80} viewBox="0 0 69 80" fill="none">
          <g clipPath="url(#braveclip0)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M65.857 19.151l1.876-4.595s-2.388-2.553-5.287-5.447c-2.898-2.894-9.037-1.192-9.037-1.192L46.417 0H21.863L14.87 7.917S8.733 6.215 5.834 9.11a247.223 247.223 0 00-5.286 5.447l1.875 4.595L.036 25.96S7.057 52.505 7.88 55.747c1.62 6.383 2.728 8.851 7.332 12.085 4.604 3.234 12.96 8.851 14.324 9.703 1.364.85 3.07 2.3 4.604 2.3 1.535 0 3.24-1.45 4.604-2.3 1.364-.852 9.72-6.469 14.324-9.703 4.604-3.234 5.712-5.702 7.332-12.085.823-3.242 7.844-29.787 7.844-29.787l-2.387-6.809z"
                  fill="url(#bravepaint0)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M51.447 12.939s8.993 10.85 8.993 13.17c0 2.32-1.131 2.931-2.269 4.137l-6.742 7.147c-.638.676-1.966 1.701-1.185 3.547.781 1.845 1.933 4.193.652 6.575-1.282 2.381-3.477 3.97-4.884 3.708-1.407-.263-4.71-1.986-5.925-2.774-1.215-.787-5.066-3.957-5.066-5.17s3.98-3.39 4.716-3.885c.735-.495 4.09-2.41 4.158-3.161.069-.752.043-.973-.947-2.83-.99-1.858-2.773-4.336-2.476-5.985.297-1.65 3.172-2.507 5.223-3.28 2.052-.774 6.002-2.234 6.495-2.461.494-.228.366-.444-1.129-.585-1.494-.142-5.736-.704-7.649-.172-1.912.532-5.18 1.342-5.444 1.771-.265.43-.498.444-.226 1.925.272 1.48 1.67 8.586 1.806 9.848.136 1.263.402 2.097-.961 2.408-1.364.311-3.659.851-4.447.851-.789 0-3.084-.54-4.448-.851-1.363-.311-1.097-1.145-.961-2.408.135-1.262 1.535-8.367 1.806-9.848.272-1.481.039-1.496-.226-1.925-.264-.43-3.532-1.239-5.444-1.77-1.913-.533-6.154.03-7.65.17-1.494.142-1.621.358-1.128.586.494.227 4.444 1.687 6.495 2.46 2.052.774 4.927 1.631 5.223 3.28.297 1.65-1.486 4.128-2.475 5.986-.99 1.857-1.017 2.078-.948 2.83.069.751 3.423 2.666 4.159 3.16.735.496 4.715 2.673 4.715 3.886s-3.85 4.383-5.065 5.17c-1.215.788-4.519 2.511-5.926 2.774-1.407.263-3.602-1.327-4.884-3.708-1.281-2.382-.129-4.73.652-6.575.781-1.846-.546-2.87-1.184-3.547l-6.743-7.147c-1.138-1.206-2.269-1.818-2.269-4.137 0-2.32 8.993-13.17 8.993-13.17s7.588 1.447 8.611 1.447c1.023 0 3.24-.851 5.286-1.532 2.047-.681 3.41-.686 3.41-.686s1.365.005 3.411.686c2.046.68 4.263 1.532 5.286 1.532 1.023 0 8.611-1.447 8.611-1.447zm-6.74 41.507c.556.348.216 1.004-.29 1.362-.508.358-7.322 5.625-7.983 6.207-.662.582-1.634 1.544-2.294 1.544-.661 0-1.633-.962-2.294-1.544-.662-.582-7.476-5.85-7.983-6.207-.507-.358-.846-1.014-.29-1.362.557-.347 2.299-1.225 4.702-2.467 2.402-1.24 5.397-2.296 5.865-2.296.467 0 3.462 1.055 5.865 2.296 2.403 1.242 4.144 2.12 4.701 2.467z"
                  fill="#fff"/>
            <mask id="a" maskUnits="userSpaceOnUse" x={5} y={0} width={58} height={15}>
              <path
                d="M53.408 7.917L46.418 0H21.861l-6.991 7.917S8.732 6.215 5.833 9.11c0 0 8.185-.738 10.999 3.83 0 0 7.588 1.447 8.611 1.447 1.023 0 3.24-.851 5.286-1.532 2.047-.681 3.41-.686 3.41-.686s1.365.005 3.411.686c2.046.68 4.263 1.532 5.286 1.532 1.023 0 8.612-1.447 8.612-1.447 2.813-4.568 10.998-3.83 10.998-3.83-2.899-2.894-9.038-1.192-9.038-1.192z"
                fill="#fff"/>
            </mask>
            <g mask="url(#a)">
              <path
                d="M53.408 7.917L46.418 0H21.861l-6.991 7.917S8.732 6.215 5.833 9.11c0 0 8.185-.738 10.999 3.83 0 0 7.588 1.447 8.611 1.447 1.023 0 3.24-.851 5.286-1.532 2.047-.681 3.41-.686 3.41-.686s1.365.005 3.411.686c2.046.68 4.263 1.532 5.286 1.532 1.023 0 8.612-1.447 8.612-1.447 2.813-4.568 10.998-3.83 10.998-3.83-2.899-2.894-9.038-1.192-9.038-1.192z"
                fill="url(#bravepaint1)"/>
            </g>
          </g>
          <defs>
            <linearGradient id="bravepaint0" x1="0.0361328" y1="40.4809" x2="68.2441" y2="40.4809"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#F50"/>
              <stop offset="0.409877" stopColor="#F50"/>
              <stop offset="0.581981" stopColor="#FF2000"/>
              <stop offset={1} stopColor="#FF2000"/>
            </linearGradient>
            <linearGradient id="bravepaint1" x1="7.04978" y1="7.29426" x2="62.4461" y2="7.29426"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF452A"/>
              <stop offset={1} stopColor="#FF2000"/>
            </linearGradient>
            <clipPath id="braveclip0">
              <path fill="#fff" d="M0 0H68.3333V80H0z"/>
            </clipPath>
          </defs>
        </svg>
      </a><a className="noselect "
             href="#"
             draggable="false">Brave</a></div>
      <div className="browser_logo"><a href="#"
                                       className="svg_link" draggable="false">
        <svg data-name="Layer 1" width={80} height={80} viewBox="0 0 75 77.5">
          <defs>
            <linearGradient id="a" x1="68.84" y1="69.05" x2="8.55" y2="10.88"
                            gradientTransform="matrix(1 0 0 -1 0 82)" gradientUnits="userSpaceOnUse">
              <stop offset="0.05" stopColor="#fff44f"/>
              <stop offset="0.37" stopColor="#ff980e"/>
              <stop offset="0.53" stopColor="#ff3647"/>
              <stop offset="0.7" stopColor="#e31587"/>
            </linearGradient>
            <radialGradient id="b" cx="-101.66" cy="317.94" r={1}
                            gradientTransform="matrix(76.8 0 0 -78.09 7861.9 24837.68)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.13" stopColor="#ffbd4f"/>
              <stop offset="0.28" stopColor="#ff980e"/>
              <stop offset="0.47" stopColor="#ff3750"/>
              <stop offset="0.78" stopColor="#eb0878"/>
              <stop offset="0.86" stopColor="#e50080"/>
            </radialGradient>
            <radialGradient id="c" cx="-101.69" cy="317.94" r={1}
                            gradientTransform="matrix(78.72 0 0 -78.09 8037.39 24869.94)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.3" stopColor="#960e18"/>
              <stop offset="0.35" stopColor="#b11927" stopOpacity="0.74"/>
              <stop offset="0.43" stopColor="#db293d" stopOpacity="0.34"/>
              <stop offset="0.5" stopColor="#f5334b" stopOpacity="0.09"/>
              <stop offset="0.53" stopColor="#ff3750" stopOpacity={0}/>
            </radialGradient>
            <radialGradient id="d" cx="-98.91" cy="315.42" r={1}
                            gradientTransform="matrix(25.21 0 0 -42.8 2540.83 13495.08)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.13" stopColor="#fff44f"/>
              <stop offset="0.53" stopColor="#ff980e"/>
            </radialGradient>
            <radialGradient id="e" cx="-99.91" cy="314.46" r={1}
                            gradientTransform="matrix(33.36 0 0 -36.56 3363.53 11557.62)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.35" stopColor="#3a8ee6"/>
              <stop offset="0.67" stopColor="#9059ff"/>
              <stop offset={1} stopColor="#c139e6"/>
            </radialGradient>
            <radialGradient id="f" cx="-97.18" cy="309.92" r={1}
                            gradientTransform="matrix(17.71 0 0 -21.57 1757.86 6717.83)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.21" stopColor="#9059ff" stopOpacity={0}/>
              <stop offset="0.97" stopColor="#6e008b" stopOpacity="0.6"/>
            </radialGradient>
            <radialGradient id="g" cx="-99.12" cy="312.02" r={1}
                            gradientTransform="matrix(26.52 0 0 -26.61 2665.96 8309.5)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.1" stopColor="#ffe226"/>
              <stop offset="0.79" stopColor="#ff7139"/>
            </radialGradient>
            <radialGradient id="h" cx="-102.19" cy="318.75" r={1}
                            gradientTransform="matrix(126.61 0 0 -106.28 12999.17 33864.86)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.11" stopColor="#fff44f"/>
              <stop offset="0.46" stopColor="#ff980e"/>
              <stop offset="0.72" stopColor="#ff3647"/>
              <stop offset="0.9" stopColor="#e31587"/>
            </radialGradient>
            <radialGradient id="i" cx="-96.63" cy="321.28" r={1}
                            gradientTransform="matrix(8.77 39.2 169.57 -37.92 -53617.58 15994.38)"
                            gradientUnits="userSpaceOnUse">
              <stop offset={0} stopColor="#fff44f"/>
              <stop offset="0.3" stopColor="#ff980e"/>
              <stop offset="0.57" stopColor="#ff3647"/>
              <stop offset="0.74" stopColor="#e31587"/>
            </radialGradient>
            <radialGradient id="j" cx="-101.58" cy="317.65" r={1}
                            gradientTransform="matrix(72.69 0 0 -71.41 7421.9 22700.03)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.14" stopColor="#fff44f"/>
              <stop offset="0.48" stopColor="#ff980e"/>
              <stop offset="0.66" stopColor="#ff3647"/>
              <stop offset="0.9" stopColor="#e31587"/>
            </radialGradient>
            <radialGradient id="k" cx="-101.82" cy="317.94" r={1}
                            gradientTransform="matrix(87.37 0 0 -78.16 8952.91 24871.19)"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.09" stopColor="#fff44f"/>
              <stop offset="0.63" stopColor="#ff980e"/>
            </radialGradient>
            <linearGradient id="l" x1="65.13" y1="68.75" x2="16.91" y2="16.07"
                            gradientTransform="matrix(1 0 0 -1 0 82)" gradientUnits="userSpaceOnUse">
              <stop offset="0.17" stopColor="#fff44f" stopOpacity="0.8"/>
              <stop offset="0.6" stopColor="#fff44f" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <path
            d="M74.8 26c-1.63-3.92-4.94-8.16-7.53-9.5a39.12 39.12 0 013.8 11.4.28.28 0 000 .06C66.83 17.36 59.63 13.1 53.75 3.81c-.3-.47-.59-1-.88-1.44-.15-.26-.29-.51-.42-.78a7.15 7.15 0 01-.56-1.48.14.14 0 000-.07.09.09 0 00-.06 0h-.12V0c-9.44 5.53-12.64 15.75-12.93 20.87a18.78 18.78 0 00-10.34 4 11.81 11.81 0 00-1-.74 17.4 17.4 0 01-.11-9.17 27.77 27.77 0 00-9 7c-1.49-1.88-1.39-8.1-1.3-9.4a7 7 0 00-1.25.66 26.29 26.29 0 00-3.67 3.15 32.69 32.69 0 00-3.51 4.2 31.74 31.74 0 00-5 11.38v.25c-.07.33-.32 2-.37 2.34a.22.22 0 010 .08 36.06 36.06 0 00-.61 5.18v.2a37.55 37.55 0 0074.56 6.36c.07-.48.12-1 .17-1.45A38.6 38.6 0 0074.8 26zM31.52 55.38l.52.26zM71.08 28z"
            transform="translate(-2.5)" fill="url(#a)"/>
          <path
            d="M74.8 26c-1.63-3.92-4.94-8.16-7.53-9.5a39 39 0 013.8 11.4v.1a34 34 0 01-1.16 25.3c-4.3 9.24-14.72 18.7-31 18.24C21.27 71 5.74 58 2.85 40.85c-.53-2.7 0-4.08.26-6.27a29 29 0 00-.61 5.18V40a37.55 37.55 0 0074.56 6.36c.07-.48.12-1 .17-1.45A38.6 38.6 0 0074.8 26z"
            transform="translate(-2.5)" fill="url(#b)"/>
          <path
            d="M74.8 26c-1.63-3.92-4.94-8.16-7.53-9.5a39 39 0 013.8 11.4v.1a34 34 0 01-1.16 25.3c-4.3 9.24-14.72 18.7-31 18.24C21.27 71 5.74 58 2.85 40.85c-.53-2.7 0-4.08.26-6.27a29 29 0 00-.61 5.18V40a37.55 37.55 0 0074.56 6.36c.07-.48.12-1 .17-1.45A38.6 38.6 0 0074.8 26z"
            transform="translate(-2.5)" fill="url(#c)"/>
          <path
            d="M56.55 30.41l.23.17A20.28 20.28 0 0053.3 26C41.62 14.36 50.24.72 51.69 0c-9.42 5.53-12.62 15.75-12.91 20.87.44 0 .87-.07 1.32-.07a18.94 18.94 0 0116.45 9.61z"
            transform="translate(-2.5)" fill="url(#d)"/>
          <path
            d="M40.12 32.74c-.06.93-3.36 4.15-4.52 4.15-10.67 0-12.41 6.46-12.41 6.46.48 5.44 4.26 9.92 8.85 12.29l.63.3c.37.16.73.31 1.11.45a16.24 16.24 0 004.88.94C57.38 58.21 61 35 47.5 28.21a13 13 0 019 2.2 18.94 18.94 0 00-16.4-9.61c-.45 0-.88 0-1.32.07a18.7 18.7 0 00-10.34 4c.56.46 1.22 1.13 2.56 2.45 2.57 2.51 9.11 5.11 9.12 5.42z"
            transform="translate(-2.5)" fill="url(#e)"/>
          <path
            d="M40.12 32.74c-.06.93-3.36 4.15-4.52 4.15-10.67 0-12.41 6.46-12.41 6.46.48 5.44 4.26 9.92 8.85 12.29l.63.3c.37.16.73.31 1.11.45a16.24 16.24 0 004.88.94C57.38 58.21 61 35 47.5 28.21a13 13 0 019 2.2 18.94 18.94 0 00-16.4-9.61c-.45 0-.88 0-1.32.07a18.7 18.7 0 00-10.34 4c.56.46 1.22 1.13 2.56 2.45 2.57 2.51 9.11 5.11 9.12 5.42z"
            transform="translate(-2.5)" fill="url(#f)"/>
          <path
            d="M26.69 23.6l.78.52a17.44 17.44 0 01-.11-9.18 27.77 27.77 0 00-9 7c.15-.02 5.64-.12 8.33 1.66z"
            transform="translate(-2.5)" fill="url(#g)"/>
          <path
            d="M2.85 40.85C5.74 58 21.27 71 38.88 71.54c16.31.46 26.73-9 31-18.24a34 34 0 001.2-25.3v-.07a.28.28 0 000 .06c1.33 8.7-3.1 17.12-10 22.82-13.48 11-26.37 6.62-29 4.83l-.54-.27c-7.86-3.75-11.1-10.91-10.41-17.05a9.64 9.64 0 01-8.89-5.59A14.15 14.15 0 0126 32.18a18.73 18.73 0 0014.1.56c0-.31-6.55-2.91-9.1-5.42-1.36-1.34-2-2-2.58-2.47a11.81 11.81 0 00-1-.74l-.78-.51c-2.74-1.78-8.18-1.68-8.36-1.68-1.49-1.88-1.39-8.1-1.3-9.4a6.41 6.41 0 00-1.25.66 26.29 26.29 0 00-3.67 3.15 32 32 0 00-3.51 4.2 31.74 31.74 0 00-5 11.38c-.03.09-1.36 5.91-.7 8.94z"
            transform="translate(-2.5)" fill="url(#h)"/>
          <path
            d="M53.3 26a20.28 20.28 0 013.48 4.55 7.12 7.12 0 01.57.46c8.51 7.84 4 18.94 3.72 19.73C68 45.07 72.41 36.65 71.08 28 66.83 17.36 59.63 13.1 53.75 3.81c-.3-.47-.59-1-.88-1.44-.15-.26-.29-.51-.42-.78a7.15 7.15 0 01-.56-1.48.14.14 0 000-.07.09.09 0 00-.06 0h-.12C50.24.72 41.62 14.36 53.3 26z"
            transform="translate(-2.5)" fill="url(#i)"/>
          <path
            d="M57.35 31a7.12 7.12 0 00-.57-.46l-.23-.17a13 13 0 00-9-2.2c13.5 6.75 9.88 30-8.84 29.12a16.37 16.37 0 01-4.89-.94c-.37-.14-.73-.29-1.1-.45l-.63-.3c2.62 1.79 15.51 6.14 29-4.83.31-.77 4.77-11.89-3.74-19.77z"
            transform="translate(-2.5)" fill="url(#j)"/>
          <path
            d="M23.19 43.35s1.74-6.46 12.41-6.46c1.16 0 4.46-3.22 4.52-4.15S33.29 35.6 26 32.18a14.15 14.15 0 00-13.8.56 9.64 9.64 0 008.89 5.59c-.69 6.14 2.55 13.3 10.41 17.05l.52.26c-4.57-2.37-8.35-6.85-8.83-12.29z"
            transform="translate(-2.5)" fill="url(#k)"/>
          <path
            d="M74.8 26c-1.63-3.92-4.94-8.16-7.53-9.5a39.12 39.12 0 013.8 11.4.28.28 0 000 .06C66.83 17.36 59.63 13.1 53.75 3.81c-.3-.47-.59-1-.88-1.44-.15-.26-.29-.51-.42-.78a7.15 7.15 0 01-.56-1.48.14.14 0 000-.07.09.09 0 00-.06 0h-.12V0c-9.44 5.53-12.64 15.75-12.93 20.87.43 0 .87-.07 1.32-.07a18.94 18.94 0 0116.45 9.61 13 13 0 00-9-2.2c13.5 6.75 9.88 30-8.84 29.12a16.37 16.37 0 01-4.89-.94c-.37-.14-.74-.29-1.1-.45l-.63-.3-.54-.27.52.26c-4.59-2.37-8.37-6.85-8.85-12.29 0 0 1.74-6.46 12.41-6.46 1.16 0 4.46-3.22 4.52-4.15 0-.31-6.55-2.91-9.1-5.42-1.36-1.34-2-2-2.58-2.47a11.81 11.81 0 00-1-.74 17.4 17.4 0 01-.11-9.17 27.92 27.92 0 00-9 7c-1.49-1.88-1.39-8.1-1.3-9.4a7.41 7.41 0 00-1.26.66 26.79 26.79 0 00-3.66 3.15 32.69 32.69 0 00-3.51 4.2 31.74 31.74 0 00-5 11.38v.25c-.07.33-.39 2-.43 2.36a44.15 44.15 0 00-.56 5.24V40a37.55 37.55 0 0074.56 6.36c.07-.48.12-1 .17-1.45A38.6 38.6 0 0074.8 26zm-3.73 1.93z"
            transform="translate(-2.5)" fill="url(#l)"/>
        </svg>
      </a><a className="noselect " href="#"
             draggable="false">Firefox</a></div>
      <div className="browser_logo"><a
        href="#"
        className="svg_link" draggable="false">
        <svg width={80} height={80} viewBox="0 0 80 80" fill="none">
          <g clipPath="url(#edgeclip0)">
            <path
              d="M72.201 59.537a29.275 29.275 0 01-3.293 1.472 31.83 31.83 0 01-11.218 2.018c-14.785 0-27.665-10.17-27.665-23.222a9.837 9.837 0 015.133-8.534c-13.373.563-16.81 14.499-16.81 22.663 0 23.085 21.276 25.426 25.86 25.426 2.471 0 6.199-.719 8.436-1.425l.41-.138A40.102 40.102 0 0073.863 61.3a1.25 1.25 0 00-1.663-1.762z"
              fill="url(#edgepaint0linear)"/>
            <path opacity="0.35"
                  d="M72.201 59.537a29.275 29.275 0 01-3.293 1.472 31.83 31.83 0 01-11.218 2.018c-14.785 0-27.665-10.17-27.665-23.222a9.837 9.837 0 015.133-8.534c-13.373.563-16.81 14.499-16.81 22.663 0 23.085 21.276 25.426 25.86 25.426 2.471 0 6.199-.719 8.436-1.425l.41-.138A40.102 40.102 0 0073.863 61.3a1.25 1.25 0 00-1.663-1.762z"
                  fill="url(#edgepaint1radial)"/>
            <path
              d="M33.037 75.435a24.748 24.748 0 01-7.106-6.668 25.223 25.223 0 019.227-37.496c.975-.459 2.64-1.29 4.856-1.25a10.11 10.11 0 018.027 4.063 9.96 9.96 0 011.988 5.83c0-.065 7.643-24.872-24.998-24.872C11.315 15.042.035 28.059.035 39.48a40.667 40.667 0 003.784 17.498 39.995 39.995 0 0048.864 20.97 23.607 23.607 0 01-19.617-2.5l-.028-.013z"
              fill="url(#edgepaint2linear)"/>
            <path opacity="0.41"
                  d="M33.037 75.435a24.748 24.748 0 01-7.106-6.668 25.223 25.223 0 019.227-37.496c.975-.459 2.64-1.29 4.856-1.25a10.11 10.11 0 018.027 4.063 9.96 9.96 0 011.988 5.83c0-.065 7.643-24.872-24.998-24.872C11.315 15.042.035 28.059.035 39.48a40.667 40.667 0 003.784 17.498 39.995 39.995 0 0048.864 20.97 23.607 23.607 0 01-19.617-2.5l-.028-.013z"
                  fill="url(#edgepaint3radial)"/>
            <path
              d="M47.598 46.514c-.253.328-1.031.78-1.031 1.768 0 .816.53 1.6 1.474 2.26 4.494 3.124 12.965 2.711 12.987 2.711a18.61 18.61 0 009.458-2.609 19.18 19.18 0 009.508-16.523c.081-7.002-2.5-11.658-3.543-13.72C69.83 7.449 55.538 0 39.996 0A39.995 39.995 0 000 39.433c.15-11.417 11.499-20.638 24.997-20.638 1.094 0 7.33.106 13.124 3.146 5.105 2.681 7.78 5.918 9.64 9.127 1.93 3.334 2.274 7.546 2.274 9.224s-.856 4.166-2.437 6.221z"
              fill="url(#edgepaint4radial)"/>
            <path
              d="M47.598 46.514c-.253.328-1.031.78-1.031 1.768 0 .816.53 1.6 1.474 2.26 4.494 3.124 12.965 2.711 12.987 2.711a18.61 18.61 0 009.458-2.609 19.18 19.18 0 009.508-16.523c.081-7.002-2.5-11.658-3.543-13.72C69.83 7.449 55.538 0 39.996 0A39.995 39.995 0 000 39.433c.15-11.417 11.499-20.638 24.997-20.638 1.094 0 7.33.106 13.124 3.146 5.105 2.681 7.78 5.918 9.64 9.127 1.93 3.334 2.274 7.546 2.274 9.224s-.856 4.166-2.437 6.221z"
              fill="url(#edgepaint5radial)"/>
          </g>
          <defs>
            <linearGradient id="edgepaint0linear" x1="18.3479" y1="55.3219" x2="74.0729" y2="55.3219"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#0C59A4"/>
              <stop offset={1} stopColor="#114A8B"/>
            </linearGradient>
            <radialGradient id="edgepaint1radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(29.8029 0 0 28.3127 49.126 55.761)">
              <stop offset="0.72" stopOpacity={0}/>
              <stop offset="0.95" stopOpacity="0.53"/>
              <stop offset={1}/>
            </radialGradient>
            <linearGradient id="edgepaint2linear" x1="47.7259" y1="31.1495" x2="12.9204" y2="69.0609"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#1B9DE2"/>
              <stop offset="0.16" stopColor="#1595DF"/>
              <stop offset="0.67" stopColor="#0680D7"/>
              <stop offset={1} stopColor="#0078D4"/>
            </linearGradient>
            <radialGradient id="edgepaint3radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="rotate(-81.384 47.186 18.265) scale(44.8156 36.2065)">
              <stop offset="0.76" stopOpacity={0}/>
              <stop offset="0.95" stopOpacity="0.5"/>
              <stop offset={1}/>
            </radialGradient>
            <radialGradient id="edgepaint4radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(-2.53008 63.25222 -134.71427 -5.38855 8.081 14.804)">
              <stop stopColor="#35C1F1"/>
              <stop offset="0.11" stopColor="#34C1ED"/>
              <stop offset="0.23" stopColor="#2FC2DF"/>
              <stop offset="0.31" stopColor="#2BC3D2"/>
              <stop offset="0.67" stopColor="#36C752"/>
            </radialGradient>
            <radialGradient id="edgepaint5radial" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(8.51628 29.19869 -23.74435 6.92543 75.014 24.187)">
              <stop stopColor="#66EB6E"/>
              <stop offset={1} stopColor="#66EB6E" stopOpacity={0}/>
            </radialGradient>
            <clipPath id="edgeclip0">
              <path fill="#fff" d="M0 0H79.996V80H0z"/>
            </clipPath>
          </defs>
        </svg>
      </a><a className="noselect "
             href="#"
             draggable="false">Edge</a></div>
    </ScrollContainer>
  </section>)
}
