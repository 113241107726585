import Head from "next/head";

export default function CommonHead() {
  return (<Head>
    <title>XXXX COMPANY</title>
    <link rel="icon" href="/favicon.ico"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <meta name="msapplication-TileColor" content="#da532c"/>
    <meta name="theme-color" content="#ffffff"/>
    <meta
      name="description"
      content="dsadsdsadsa"
    />
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:site" content="@phantom"/>
    <meta name="twitter:creator" content="@phantom"/>
    <meta
      name="twitter:image"
      content="https://ho4oo9g8erzbwpsdwe049cao.aia.one/img/phantom-og.png"
    />
    <meta name="twitter:url" content="https://ho4oo9g8erzbwpsdwe049cao.aia.one/"/>
    <title>COMPANY PAGES</title>
    <meta name="robots" content="noindex,nofollow"/>
    <meta name="googlebot" content="noindex,nofollow"/>
    <meta property="og:url" content="https://ho4oo9g8erzbwpsdwe049cao.aia.one/"/>
    <meta property="og:type" content="website"/>
    <meta
      property="og:title"
      content="dsadsadsadsa"
    />
    <meta
      property="og:description"
      content="dqgd9wq8dv8q"
    />
    <meta
      property="og:image"
      content="https://ho4oo9g8erzbwpsdwe049cao.aia.one/img/phantom-og.png"
    />
    <meta
      property="og:image:alt"
      content="dsadsadsadsa - dsadsadsadsa"
    />
    <meta property="og:locale" content="en_IE"/>
    <meta property="og:site_name" content="Phantom"/>
  </Head>)
}
