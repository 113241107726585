import styled from "styled-components";
import Link from "next/link";

const FooterListStyled = styled.div`
  width: 50%;
  margin-top: 1.25rem;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  ul {
    margin-top: 9px;

    li {
      margin-top: 0.5rem;

      a {
        font-size: 1rem;
        line-height: 1.5rem;
        opacity: 0.5;

        &:hover {
          color: rgb(138, 129, 248);
        }
      }
    }
  }
`


export default function Footer() {
  return (<footer className="px-8 py-20  mt-[128px] bg-[#222222]">
    <div className="flex flex-col justify-between max-w-[1024px] mx-auto md:flex-row">
      <div>
        <div className="flex space-x-3"><h3 className="text-4xl font-medium">XXXX</h3></div>
        <p className="pt-4">© Acme, Inc.</p></div>
      <div className="flex flex-wrap justify-between mt-8 md:w-1/2 sm:flex-nowrap md:mt-0">
        <FooterListStyled><h4>Product</h4>
          <ul>
            <li><Link href="/"><a>Overview</a></Link></li>
            <li><Link href="/download"><a>Download</a></Link></li>
            <li><Link href="/security"><a>Security</a></Link></li>
          </ul>
        </FooterListStyled>
        <FooterListStyled><h4>Resource</h4>
          <ul>
            <li><Link href="/blog"><a>Blog</a></Link></li>
            <li><Link href="/terms"><a>Terms</a></Link></li>
            <li><Link href="/terms"><a>Privacy</a></Link></li>
          </ul>
        </FooterListStyled>
        <FooterListStyled><h4>Company</h4>
          <ul>
            <li><Link href="/about"><a>About</a></Link></li>
            <li><Link href="/jobs"><a>Jobs</a></Link></li>
          </ul>
        </FooterListStyled>
      </div>
    </div>
  </footer>)
}
