import Link from "next/link";
import styled from 'styled-components'

const NavbarLink = styled.a`
  color: rgba(255, 255, 255, 0.5);
  transition: opacity 500ms ease 0s, color 500ms ease 0s;
  text-transform: capitalize;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: #8a81f8;
  }

  &.active {
    color: ${props => props.color};;
  }
`

const StyledPopoverButton = styled.button`
  --tw-bg-opacity: 1;
  color: rgb(119, 119, 119);
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;

  &:focus {
    outline: transparent solid 2px;
    outline-offset: 2px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const StyledPopoverButton2 = styled.button`
  --tw-bg-opacity: 1;
  color: rgb(119, 119, 119);
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
`

function Nav() {
  return (<nav className="hidden space-x-[50px] md:justify-center md:flex">
    <Link href={`/`}><NavbarLink color={`white`} className={`active`}>Home</NavbarLink></Link>
    <Link href={`/security`}><NavbarLink color={`rgba(255, 255, 255, 0.5)`}
    >Security</NavbarLink></Link>
    <Link href={`/blog`}><NavbarLink color={`rgba(255, 255, 255, 0.5)`}
    >Blog</NavbarLink></Link>
  </nav>);
}

export default function Header() {
  return (<header
    className="sticky top-0 z-30 h-[66px] bg-skin-fill bg-opacity-60 backdrop-filter firefox:bg-opacity-90 backdrop-blur-xl px-[30px] py-[14px]">
    <div className="flex items-center justify-between h-full md:mx-auto">
      <Link href={`/`}>
        <a className="cursor-pointer flex items-center justify-between">
          <img className="noselect"
               src="/assets/images/phantom-logo-purple.svg"
               alt="xxx" draggable="false"/>
        </a>
      </Link>

      <Nav/>
      <div className="flex items-center space-x-2">
        <div className="w-[143px]"><a
          rel="noopener noreferrer" draggable="false"
          onClick={() => {
            throw new Error('Test 2')
          }}
          className="btn hover:scale-98 active:scale-95 w-[143px] text-base md:grid place-content-center hidden noselect">Download
          Now</a></div>
        <StyledPopoverButton
          // id="headlessui-popover-button-1"
          name={`name`}
          type="button" aria-expanded="false"><span className="sr-only">Open menu</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
               className="w-9 h-9" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </StyledPopoverButton>
      </div>
    </div>
    <div
      style={{display: 'none'}}
      className="absolute inset-x-0 top-0 z-30 px-6 py-8 mx-4 transition origin-top-right transform rounded-lg shadow-lg mx md:hidden bg-skin-fill"
      id="headlessui-popover-panel-2">
      <>
        <div className="flex justify-between">
          <a className="text-xl focus:outline-none" href="/" tabIndex={0}>Cxxxx</a>
          <StyledPopoverButton2 type="button"><span
            className="sr-only">Close menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 className="w-6 h-6" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </StyledPopoverButton2>
        </div>
        <ul className="flex flex-col"><a href="/">
          <button className="py-1 my-1 capitalize transition duration-200 opacity-50 hover:opacity-100"
                  type="button">overview
          </button>
        </a><a href="/security">
          <button className="py-1 my-1 capitalize transition duration-200 opacity-50 hover:opacity-100"
                  type="button">security
          </button>
        </a><a href="/blog">
          <button className="py-1 my-1 capitalize transition duration-200 opacity-50 hover:opacity-100"
                  type="button">blog
          </button>
        </a>
        </ul>
      </>
    </div>
  </header>)
}
